// main.js v2.3.0 date 16.03.24

(function ($) {
    "use strict";

    /*****************************
    * Commons Variables
    *****************************/
    var $window = $(window),
        $body = $('body');

    var sitename = (location.protocol || 'https:') + '//' + (location.hostname || 'kommek.ru');

    /**********************
    * Sticky Menu
    ***********************/
    $window.on('scroll', function (event) {
        var scroll = $window.scrollTop();
        if (scroll < 135) {
            $(".sticky-header").removeClass("is-sticky");
        } else {
            $(".sticky-header").addClass("is-sticky");
        }
    });


    /*****************************
    * Off Canvas Function
    *****************************/
    (function () {
        var $offCanvasToggle = $('.offcanvas-toggle'),
            $offCanvas = $('.offcanvas'),
            $offCanvasOverlay = $('.offcanvas-overlay'),
            $mobileMenuToggle = $('.mobile-menu-toggle');
        $offCanvasToggle.on('click', function (e) {
            e.preventDefault();
            var $this = $(this),
                $target = $this.attr('href');
            $body.addClass('offcanvas-open');
            $($target).addClass('offcanvas-open');
            $offCanvasOverlay.fadeIn();
            if ($this.parent().hasClass('mobile-menu-toggle')) {
                $this.addClass('close');
            }
        });
        $('.offcanvas-close, .offcanvas-overlay').on('click', function (e) {
            e.preventDefault();
            $body.removeClass('offcanvas-open');
            $offCanvas.removeClass('offcanvas-open');
            $offCanvasOverlay.fadeOut();
            $mobileMenuToggle.find('a').removeClass('close');
        });
    })();


    /**************************
     * Offcanvas: Menu Content
     **************************/
    function mobileOffCanvasMenu() {
        var $offCanvasNav = $('.offcanvas-menu'),
            $offCanvasNavSubMenu = $offCanvasNav.find('.sub-menu');

        /*Add Toggle Button With Off Canvas Sub Menu*/
        $offCanvasNavSubMenu.parent().prepend('<div class="offcanvas__menu-expand"></div>');

        /*Category Sub Menu Toggle*/
        $offCanvasNav.on('click', 'li a, .offcanvas__menu-expand', function (e) {
            var $this = $(this);
            if ($this.attr('href') === '#' || $this.hasClass('offcanvas__menu-expand')) {
                e.preventDefault();
                if ($this.siblings('ul:visible').length) {
                    $this.parent('li').removeClass('active');
                    $this.siblings('ul').slideUp();
                    $this.parent('li').find('li').removeClass('active');
                    $this.parent('li').find('ul:visible').slideUp();
                } else {
                    $this.parent('li').addClass('active');
                    $this.closest('li').siblings('li').removeClass('active').find('li').removeClass('active');
                    $this.closest('li').siblings('li').find('ul:visible').slideUp();
                    $this.siblings('ul').slideDown();
                }
            }
        });
    }
    mobileOffCanvasMenu();


    /**********************
     * Vertical Menu
     ***********************/
    $('.header-menu-vertical .menu-title').on('click', function (event) {
        $('.header-menu-vertical .menu-content').slideToggle(500);
    });

    $('.menu-content').each(function () {
        var $ul = $(this),
            $lis = $ul.find('.menu-item:gt(5)'),
            isExpanded = $ul.hasClass('expanded');
        $lis[isExpanded ? 'show' : 'hide']();

        if ($lis.length > 0) {
            $ul
                .append($('<li class="expand">' + (isExpanded ? '<a href="javascript:;"><div><i class="fak fa-minus-square"></i>Скрыть Категории</div></a>' : '<a href="javascript:;"><div><i class="fak fa-plus-square"></i>Все Категории</div></a>') + '</li>')
                    .on('click', function (event) {
                        var isExpanded = $ul.hasClass('expanded');
                        event.preventDefault();
                        $(this).html(isExpanded ? '<a href="javascript:;"><div><i class="fak fa-plus-square"></i>Все Категории</div></a>' : '<a href="javascript:;"><div><i class="fak fa-minus-square"></i>Скрыть Категории</div></a>');
                        $ul.toggleClass('expanded');
                        $lis.toggle(300);
                    }));
        }
    });

    /***************************** 
    * Category more toggle  
    *****************************/

    $(".category-menu li.hidden").hide();
    $("#more-btn").on('click', function (e) {

        e.preventDefault();
        $(".category-menu li.hidden").toggle(500);
        var htmlAfter = '<i class="ion-ios-minus-empty" aria-hidden="true"></i> Less Categories';
        var htmlBefore = '<i class="ion-ios-plus-empty" aria-hidden="true"></i> More Categories';


        if ($(this).html() == htmlBefore) {
            $(this).html(htmlAfter);
        } else {
            $(this).html(htmlBefore);
        }
    });

    ///******************************
    // * Hero Slider - [Single Grid]
    // *****************************/
    //    $('.hero').slick({
    //        arrows: true,
    //        fade: true,
    //        dots: true,
    //        easing: 'linear',
    //        speed: 2000,
    //        autoplay: true,
    //        autoplaySpeed: 4000,
    //        prevArrow: '<button type="button" class="hero-slider__arrow hero-slider__arrow--left"><i class="far fa-chevron-left"></i></button>',
    //        nextArrow: '<button type="button" class="hero-slider__arrow hero-slider__arrow--right"><i class="far fa-chevron-right"></i></button>',
    //        responsive: [
    //
    //            {
    //                breakpoint: 768,
    //                settings: {
    //                    arrows: false,
    //                }
    //            },
    //        ]
    //    });

    /************************************************
     * Product TOP5order Slider
     ***********************************************/
    //if ($('.product-top5order-slider').length){
    $('.product-top5order').each(function () {
        var objSliderGoods = $(this);
        var slGoodsId = objSliderGoods.attr('id') || '';

        if (slGoodsId) {
            var param = '';
            var add_url = '';

            param = objSliderGoods.attr('data-tn') || '';
            if (param) { add_url += '&disOrdTn=' + param; }

            param = objSliderGoods.attr('data-type') || '';
            if (param) { add_url += '&disOrdType=' + param; }

            param = objSliderGoods.attr('data-data') || '';
            if (param) { add_url += '&disOrdData=' + param; }

            param = objSliderGoods.attr('data-visable') || '';
            if (param) { add_url += '&disOrdAll=' + param; }

            param = objSliderGoods.attr('data-limit') || '';
            if (param) { add_url += '&disOrdLimit=' + param; }

            param = objSliderGoods.attr('data-esc') || '';
            if (param) { add_url += '&disOrdEsc=' + param; }

            param = objSliderGoods.attr('data-menu') || '';
            if (param) { add_url += '&disOrdMenu=' + param; }

            param = objSliderGoods.attr('data-good') || '';
            if (param) { add_url += '&disOrdGood=' + param; }

            param = objSliderGoods.attr('data-basket') || '';
            if (param) { add_url += '&basket=' + param; }

            param = objSliderGoods.attr('data-back') || '';
            if (param) { add_url += '&back=' + param; }

            kmkCMS.loadXMLDoc(sitename + '/cgi-bin/top5order.cgi?disOrdCase=' + slGoodsId + add_url, slGoodsId, slGoodsId + '_loader',
                product_ui_setting);
        } else {
            product_ui_setting(objSliderGoods);
        }
    });
    //}

    /************************************************
     * Product SRVMarket Slider
     ***********************************************/

    //if ($('.product-srvmarket').length){

    $('.product-srvmarket').each(function () {
        var objSliderGoods = $(this);
        if (objSliderGoods[0].querySelector('a[name^="good_show_"]')) return; //already done
        var idGoods = objSliderGoods.attr('data-good') || '';

        if (idGoods) {
            var param = '';
            var add_url = '&htmhead=0';

            param = objSliderGoods.attr('data-param') || '';
            if (param) { add_url += '&param=' + param; }

            param = objSliderGoods.attr('data-tn') || '';
            if (param) { add_url += '&tn=' + param; }

            param = objSliderGoods.attr('data-tpltmp') || '';
            if (param) { add_url += '&tpltmp=' + param; }

            param = objSliderGoods.attr('data-type') || '';
            if (param) { add_url += '&type=' + param; }

            param = objSliderGoods.attr('data-visable') || '';
            if (param) { add_url += '&vis=' + param; }

            param = objSliderGoods.attr('data-bonus') || '';
            if (param) { add_url += '&bonus=' + param; }

            param = objSliderGoods.attr('data-basket') || '';
            if (param) { add_url += '&basket=' + param; }

            param = objSliderGoods.attr('data-back') || '';
            if (param) { add_url += '&back=' + param; }

            kmkCMS.loadXMLDoc(sitename + '/cgi-bin/srv_market_show.cgi?id_good=' + idGoods + add_url, objSliderGoods[0], null,
                //product_default_slider);
                product_ui_setting);
        }
    });
    //}
    function product_ui_setting(slGoodsId) {
        if (typeof window.MKTbasket != 'undefined') new MKTbasket(slGoodsId);
        product_default_slider(slGoodsId);
    }

    function product_default_slider(slGoodsId) {
        /************************************************
         * Product Slider - Style: Default [4 Grid, 1 Rows]
         ***********************************************/
        if ($(slGoodsId).hasClass('product-default-slider-4grid-1rows')) {
            $(slGoodsId).slick({
                arrows: true,
                dots: true,
                infinite: true,
                slidesToShow: 5,
                slidesToScroll: 5,
                rows: 1,
                easing: 'ease-out',
                speed: 800,
                prevArrow: '<button type="button" class="default-slider__arrow default-slider__arrow--left prevArrow"><i class="far fa-chevron-left"></button>',
                nextArrow: '<button type="button"  class="default-slider__arrow default-slider__arrow--right nextArrow"><i class="far fa-chevron-right"></button>',
                responsive: [
                    {
                        breakpoint: 1200,
                        settings: {
                            slidesToShow: 4,
                            slidesToScroll: 4
                        }
                    },
                    {
                        breakpoint: 992,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3
                        }
                    },
                    {
                        breakpoint: 575,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                            arrows: false,
                        }
                    },
                ]
            });
        }
        /************************************************
         * Product Slider - Style: Default [4 Grid, 2 Rows]
         ***********************************************/
        if ($(slGoodsId).hasClass('product-default-slider-4grid-2rows')) {
            $(slGoodsId).slick({
                arrows: true,
                slidesToShow: 5,
                slidesToScroll: 5,
                infinite: false,
                rows: 2,
                easing: 'ease-out',
                speed: 800,
                prevArrow: '<button type="button" class="default-slider__arrow default-slider__arrow--left prevArrow"><i class="far fa-chevron-left"></button>',
                nextArrow: '<button type="button"  class="default-slider__arrow default-slider__arrow--right nextArrow"><i class="far fa-chevron-right"></button>',
                responsive: [
                    {
                        breakpoint: 1200,
                        settings: {
                            slidesToShow: 4,
                            slidesToScroll: 4
                        }
                    },
                    {
                        breakpoint: 992,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3
                        }
                    },
                    {
                        breakpoint: 575,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2
                        }
                    },
                ]
            });
        }
        /************************************************
         * Product Slider - Style: Default [1 Grid, 1 Rows]
         ***********************************************/
        if ($(slGoodsId).hasClass('product-default-slider-1grid-1rows')) {
            $(slGoodsId).slick({
                arrows: true,
                easing: 'linear',
                speed: 2000,
                dots: true,
                adaptiveHeight: true,
                prevArrow: '<button type="button" class="default-slider__arrow default-slider__arrow--left prevArrow"><i class="far fa-chevron-left"></button>',
                nextArrow: '<button type="button"  class="default-slider__arrow default-slider__arrow--right nextArrow"><i class="far fa-chevron-right"></button>',
                responsive: [
                    {
                        breakpoint: 575,
                        settings: {
                            arrows: false,
                        }
                    },
                ]
            });
        }
        var is_scroll = $(slGoodsId).attr('data-noscroll') || 0;
        if (is_scroll != 'true') kmkCMS.scrollToAnchor(slGoodsId);
    }

    ///************************************************
    // * Testimonial Slider - Style:  [1 Grid, 1 Rows]
    // ***********************************************/
    $('.testimonial__slider').each(function () {
        var $this = $(this);
        $this.slick({
            arrows: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            easing: 'ease-out',
            speed: 900,
            prevArrow: '<button type="button" class="default-slider__arrow default-slider__arrow--left prevArrow"><i class="far fa-chevron-left"></button>',
            nextArrow: '<button type="button"  class="default-slider__arrow default-slider__arrow--right nextArrow"><i class="far fa-chevron-right"></button>',
        });
    });

    // /***********************************
    // * Blog Image Slider
    // ************************************/

    // $('.blog__img--inner').each(function () {
    //     var $objImgInner = $(this);
    //     var $objPosition = $objImgInner.closest("[class^='blog__type-']");
    //     if ($objPosition) {
    //         //var $objImg = $objPosition.siblings('.blog__img');
    //         var $objImg = $objPosition.find('.blog__img').eq(0);
    //         if ($objImg) {

    //             var img = $objImg.html();
    //             $objImg.empty();
    //             //var arrStrAttribute = getDataAttr($objImgInner);

    //             var $objDiv = $objImgInner.find('.blog__img');
    //             var imgs = img;
    //             // $objDiv.forEach($objImgIn => {
    //             //     imgs += $objImgIn.html();
    //             // });

    //             $objImg.append('<div class="inner-slider-grid-1 pos-relative">' + imgs + '</div>');
    //         }
    //     }
    // });

    // $('.inner-slider-grid-1').slick({
    //     slidesToShow: 1,
    //     slidesToScroll: 1,
    //     focusOnSelect: true,
    //     arrows: true,
    //     prevArrow: '<button type="button" class="inner-slider__arrow inner-slider__arrow--left prevArrow"><i class="fak fa-chevron-left"></i></button>',
    //     nextArrow: '<button type="button"  class="inner-slider__arrow inner-slider__arrow--right nextArrow"><i class="fak fa-chevron-right"></i></button>',
    // });

    // function inner_default_slider(slNewsId) {
    //     /************************************************
    //     * Product Slider - Style: Default [4 Grid, 1 Rows]
    //     ***********************************************/
    //     if ($(slNewsId).hasClass('inner-slider-grid-1')) {
    //         $(slNewsId).slick({
    //             slidesToShow: 1,
    //             slidesToScroll: 1,
    //             focusOnSelect: true,
    //             arrows: true,
    //             prevArrow: '<button type="button" class="inner-slider__arrow inner-slider__arrow--left prevArrow"><i class="fak fa-chevron-left"></i></button>',
    //             nextArrow: '<button type="button"  class="inner-slider__arrow inner-slider__arrow--right nextArrow"><i class="fak fa-chevron-right"></i></button>',
    //         });
    //     }
    // }

    //
    // /***********************************
    // * Team Slider
    // ************************************/
    //$('.inner-slider-grid-4').slick({
    //     slidesToShow: 4,
    //     slidesToScroll: 1,
    //     focusOnSelect: true,
    //     arrows: true,
    //     prevArrow: '<button type="button" class="inner-slider__arrow inner-slider__arrow--left prevArrow"><i class="far fa-chevron-left"></i></button>',
    //     nextArrow: '<button type="button"  class="inner-slider__arrow inner-slider__arrow--right nextArrow"><i class="far fa-chevron-right"></i></button>',
    //     responsive: [
    //
    //         {
    //             breakpoint: 992,
    //             settings: {
    //                 slidesToShow: 3,
    //             }
    //         },
    //         {
    //             breakpoint: 768,
    //             settings: {
    //                 slidesToShow: 2,
    //             }
    //         },
    //         {
    //             breakpoint: 576,
    //             settings: {
    //                 slidesToShow: 1,
    //             }
    //         }
    //     ]
    // });

    $('.blog-feed').each(function () {
        var objSliderNews = $(this);
        var slNewsNs = objSliderNews.attr('data-news') || '';

        if (slNewsNs) {
            var param = '';
            var add_url = '&htmhead=0';

            param = objSliderNews.attr('data-panel') || '';
            if (param) { add_url += '&panel=' + param; }
            param = objSliderNews.attr('data-mode') || '';
            if (param) { add_url += '&mode=' + param; }
            param = objSliderNews.attr('data-tn') || '';
            if (param) { add_url += '&tn=' + param; }
            param = objSliderNews.attr('data-order') || '';
            if (param) { add_url += '&order=' + param; }
            param = objSliderNews.attr('data-limit') || '';
            if (param) { add_url += '&limit=' + param; }

            kmkCMS.loadXMLDoc(sitename + '/cgi-bin/srv_news_show.cgi?id_news=' + slNewsNs + add_url, objSliderNews[0], null,
                blog_feed_default_slider);
        } else {
            blog_feed_default_slider(objSliderNews);
        }
    });

    function blog_feed_default_slider(slNewsId) {
        /************************************************
         * Product Slider - Style: Default [4 Grid, 1 Rows]
         ***********************************************/
        var $this = $(slNewsId);
        if ($this.hasClass('blog-feed-slider-4grid')) {
            $this.slick({
                dots: true,
                slidesToShow: 4,
                slidesToScroll: 4,
                easing: 'linear',
                speed: 1000,
                prevArrow: '<button type="button" class="default-slider__arrow default-slider__arrow--left prevArrow"><i class="far fa-chevron-left"></button>',
                nextArrow: '<button type="button"  class="default-slider__arrow default-slider__arrow--right nextArrow"><i class="far fa-chevron-right"></button>',
                responsive: [
                    {
                        breakpoint: 992,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3,
                            speed: 800,
                        }
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                            arrows: false,
                            speed: 500,

                        }
                    },
                    {
                        breakpoint: 480,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            arrows: false,
                            speed: 300,
                        }
                    }
                ]
            });
        }
        if ($this.hasClass('blog-feed-slider-3grid')) {
            $this.slick({
                dots: true,
                slidesToShow: 3,
                slidesToScroll: 3,
                easing: 'linear',
                speed: 1000,
                prevArrow: '<button type="button" class="default-slider__arrow default-slider__arrow--left prevArrow"><i class="far fa-chevron-left"></button>',
                nextArrow: '<button type="button"  class="default-slider__arrow default-slider__arrow--right nextArrow"><i class="far fa-chevron-right"></button>',
                autoplay: true,
                autoplaySpeed: 2000,
                responsive: [
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                            arrows: false,
                            speed: 500,

                        }
                    },
                    {
                        breakpoint: 480,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            arrows: false,
                            speed: 300,
                        }
                    }
                ]

            });
        }
        kmkCMS.scrollToAnchor(slNewsId);
    }

    /************************************************
     * Periodic SRV Slider
     ***********************************************/
    $('.periodic-feed').each(function () {
        var objSliderPer = $(this);
        var slPerId = objSliderPer.attr('data-id') || '';

        if (slPerId) {
            var param = '';
            var add_url = '';

            param = objSliderPer.attr('data-tn') || '';
            if (param) { add_url += '&tn=' + param; }

            kmkCMS.loadXMLDoc(sitename + '/cgi-bin/srv_periodic_show.cgi?id_per=' + slPerId + add_url, objSliderPer[0], null,
                periodic_feed_default_slider);
        }
    });
    function periodic_feed_default_slider(slPerId) {
        kmkCMS.scrollToAnchor(slPerId);
    }

    /************************************************
     * Company logo Slider
     ***********************************************/
    $('.company-logo__area').each(function () {
        $(this).slick({
            arrows: false,
            infinite: true,
            slidesToShow: 6,
            slidesToScroll: 1,
            swipeToSlide: true,
            easing: 'linear',
            autoplay: true,
            speed: 1000,

            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 5,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 1,
                        speed: 800,
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        speed: 500,
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        speed: 500,
                    }
                }
            ]
        });
    });

    /***********************************
    * Gallery - Horizontal
    ************************************/
    $('.product-image--large-horizontal').each(function () {
        $(this).slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            fade: true,
        });
    });
    //$('.product-image--large-horizontal').slick({
    //    slidesToShow: 1,
    //    slidesToScroll: 1,
    //    arrows: false,
    //    fade: true,
    //});

    $('.product-image--thumb-horizontal').each(function () {
        $(this).slick({
            slidesToShow: 3,
            slidesToScroll: 1,
            focusOnSelect: true,
            prevArrow: '<button type="button" class="gallery__nav gallery__nav-horizontal gallery__nav-horizontal--left prevArrow"><i class="fas fa-chevron-left"></i></button>',
            nextArrow: '<button type="button"  class="gallery__nav gallery__nav-horizontal gallery__nav-horizontal--right nextArrow"><i class="fas fa-chevron-right"></i></button>'
        });
    });
    //$('.product-image--thumb-horizontal').slick({
    //    slidesToShow: 3,
    //    slidesToScroll: 1,
    //    focusOnSelect: true,
    //    prevArrow: '<button type="button" class="gallery__nav gallery__nav-horizontal gallery__nav-horizontal--left prevArrow"><i class="fas fa-chevron-left"></i></button>',
    //    nextArrow: '<button type="button"  class="gallery__nav gallery__nav-horizontal gallery__nav-horizontal--right nextArrow"><i class="fas fa-chevron-right"></i></button>'
    //});

    /***********************************
    * Gallery - Vertical 
    ************************************/
    $('.product-image--large-vertical').each(function () {
        $(this).slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            fade: true,
        });
    });
    //$('.product-image--large-vertical').slick({
    //     slidesToShow: 1,
    //     slidesToScroll: 1,
    //     arrows: false,
    //     fade: true,
    // });

    $('.product-image--thumb-vertical').each(function () {
        $(this).slick({
            slidesToShow: 3,
            slidesToScroll: 1,
            vertical: true,
            focusOnSelect: true,
            prevArrow: '<button type="button" class="gallery__nav gallery__nav-vertical gallery__nav-vertical--up prevArrow"><i class="fas fa-chevron-up"></i></button>',
            nextArrow: '<button type="button"  class="gallery__nav gallery__nav-vertical gallery__nav-vertical--down nextArrow"><i class="fas fa-chevron-down"></i></button>'
        });
    });
    //$('.product-image--thumb-vertical').slick({
    //    slidesToShow: 3,
    //    slidesToScroll: 1,
    //    vertical: true,
    //    focusOnSelect: true,
    //    prevArrow: '<button type="button" class="gallery__nav gallery__nav-vertical gallery__nav-vertical--up prevArrow"><i class="fas fa-chevron-up"></i></button>',
    //    nextArrow: '<button type="button"  class="gallery__nav gallery__nav-vertical gallery__nav-vertical--down nextArrow"><i class="fas fa-chevron-down"></i></button>'
    //});


    // /***********************************
    // * Gallery - Slider 
    // ************************************/
    //$('.product-gallery-box--single-slider').slick({
    //     slidesToShow: 3,
    //     slidesToScroll: 1,
    //     focusOnSelect: true,
    //     arrows: true,
    //     prevArrow: '<button type="button" class="gallery__nav gallery__nav-horizontal gallery__nav-horizontal--left prevArrow"><i class="fas fa-chevron-left"></i></button>',
    //     nextArrow: '<button type="button"  class="gallery__nav gallery__nav-horizontal gallery__nav-horizontal--right nextArrow"><i class="fas fa-chevron-right"></i></button>',
    //     responsive: [
    //
    //         {
    //             breakpoint: 992,
    //             settings: {
    //                 slidesToShow: 3,
    //             }
    //         },
    //         {
    //             breakpoint: 768,
    //             settings: {
    //                 slidesToShow: 2,
    //                 arrows: false,
    //                 autoplay: true,
    //                 infinite: true,
    //             }
    //         },
    //         {
    //             breakpoint: 480,
    //             settings: {
    //                 slidesToShow: 1,
    //                 arrows: false,
    //                 autoplay: true,
    //                 infinite: true,
    //             }
    //         }
    //     ]
    // });

    /**********************
     * Price Range
     ***********************/
    //    $("#slider-range").slider({
    //      range: true,
    //      orientation: "horizontal",
    //      min: 0,
    //      max: 1000,
    //      values: [0, 1000],
    //      step: 100,
    //    
    //      slide: function (event, ui) {
    //        if (ui.values[0] == ui.values[1]) {
    //          return false;
    //        }
    //        
    //        $("#min_price").val(ui.values[0]);
    //        $("#max_price").val(ui.values[1]);
    //      }
    //    });

    /********************************
    *  Product Gallery - Image Zoom
    **********************************/
    $('#img-zoom').each(function () {
        $(this).elevateZoom({
            gallery: "gallery-zoom",
            galleryActiveClass: "zoom-active",
            containLensZoom: true,
            zoomType: "inner",
            cursor: "crosshair"
        });
    });

    //$("#img-zoom").elevateZoom({
    //      gallery: "gallery-zoom",
    //      galleryActiveClass: "zoom-active",
    //      containLensZoom: true,
    //      zoomType: "inner",
    //      cursor: "crosshair"
    //  });

    $('.blog__img--video').each(function () {
        var $objImgVideo = $(this);
        var $objPosition = $objImgVideo.closest("[class^='blog__type-']");
        if ($objPosition) {
            //var $objImg = $objPosition.siblings('.blog__img');
            var $objImg = $objPosition.find('.blog__img').eq(0);
            if ($objImg) {
                var arrStrAttribute = getDataAttr($objImgVideo);

                var $img = $objImg.html();
                $objImg.empty();

                $objImg.append('<div class="blog__video pos-relative">' + $img +
                    '<a class="popup-icon vinobox-popup" ' + arrStrAttribute + '">' +
                    '<i class="fal fa-play"></i></a></div>');
            }
        }
    });
    function getDataAttr($node) {
        //var attrs = {};
        var attrs = '';
        $.each($node[0].attributes, function (index, attribute) {
            var str = attribute.name;
            if (str.indexOf('data-') >= 0) {
                //attrs[attribute.name] = attribute.value;
                attrs += (attrs.length) ? ' ' : '';
                attrs = attrs + attribute.name + '="' + attribute.value + '"';
            }
        });
        return attrs;
    }

    /*******************
     * Basket (count of goods)
     *******************/
    if (document.querySelector('.product__basket') && typeof window.MKTbasket != 'undefined') {
        new MKTbasket();
    }

    /*****************************
     * VENOBOX Video & Img Popup
     *****************************/
    if ($('.vinobox-popup').length && typeof window.VenoBox != 'undefined') {
        new VenoBox({
            selector: '.vinobox-popup',
            overlayColor: 'rgba(0,0,0,0.75)',
            infinigall: true,
        });
    }
    //	$('.vinobox-popup').each(function () {
    //        $(this).venobox();
    //    });

    //    /*****************************
    //    *   Countdown
    //    **************************** */
    //    $('[data-countdown]').each(function () {
    //        var $this = $(this),
    //            finalDate = $(this).data('countdown');
    //        $this.countdown(finalDate, function (event) {
    //            $this.html(event.strftime('<div class="cdown day">%-D <p>Days</p></div> <div class="cdown hour">%-H <p>Hours</p></div> <div class="cdown minutes">%M <p>Mins</p></div> <div class="cdown second">%S <p>Sec</p></div>'));
    //        });
    //    });

    ///*****************************
    //* Create an account toggle
    //*****************************/
    //$(".creat-account").on("click", function () {
    //  $(".open-create-account").slideToggle(1000);
    //});
    //
    //$(".shipping-account").on("click", function () {
    //  $(".open-shipping-account").slideToggle(1000);
    //});


    /****************************
    * Set Telephone Mask
    *****************************/
    $('input[name=NewBsk_TelPh],[name=NewReg_TelPh]').each(function () {
        $(this).mask("8(999) 999-9999");
    });

    /*****************************
    * DaData Suggestions Activate
    *****************************/
    if ($('form[name=OrderSaveUnReg]').length) {
        if (typeof (dadata) !== 'undefined') {
            dadata('NewBsk_chosenPost', 'NewBsk_chosenCity', 'NewBsk_addresPost');
        }
    }
    if ($('form[name=NewAddresForm]').length) {
        if (typeof (dadata) !== 'undefined') {
            dadata('NewReg_Index', 'NewReg_City', 'NewReg_Addres');
        }
    }
    if ($('form[name=NewOrganisationForm]').length) {
        if (typeof (dadata_org) !== 'undefined') {
            dadata_org('NewReg_Org');
        }
        if (typeof (dadata_bank) !== 'undefined') {
            dadata_bank('NewReg_Org_BankName');
        }
    }


    /****************************
    * Password Hide/ Show Toggle
    *****************************/
    $(".password__toggle--btn").on("click", function () {
        $(this).toggleClass("password__icon_eye password__icon_eye-slash");
        var input = $($(this).attr("data-bs-toggle"));
        if (input.attr("type") == "password") {
            input.attr("type", "text");
        } else {
            input.attr("type", "password");
        }
    });

    /****************************
    * Accordian - FAQ
    *****************************/
    const accordianItemHeaders = document.querySelectorAll(".accordian-item-header");

    accordianItemHeaders.forEach(accordianItemHeader => {
        accordianItemHeader.addEventListener("click", () => {
            const current = document.querySelector(".accordian-item-header.active");

            if (current && current !== accordianItemHeader) {
                current.classList.toggle("active");
                current.nextElementSibling.style.maxHeight = 0;
            }
            accordianItemHeader.classList.toggle("active");

            const accordianItemBody = accordianItemHeader.nextElementSibling;

            if (accordianItemHeader.classList.contains("active")) {
                accordianItemBody.style.maxHeight = accordianItemBody.scrollHeight + "px";
            } else {
                accordianItemBody.style.maxHeight = 0;
            }
        });
    });

    /*----------------------------------
        Scroll To Top Active
    -----------------------------------*/
    $body.materialScrollTop();

    /*----------------------------------
        Scroll To Anchor
    -----------------------------------*/
    $('#toPayment').click(function () {
        $('html, body').animate({ scrollTop: $('#paymentblock').offset().top - 70 }, 800);
        return false;
    });


    // Search category
    //function showSearchCategory() {
    //  var searchCategory = $('li#nav99>ul').html();
    //  var searchWrap = $('div.search-wrap > div.wrap');
    //  searchWrap.append('<div class="search-category"><h3>Поиск по категориям</h3><ul>' + searchCategory + '</ul></div>');
    //}
    //header-menu-vertical

    //function showSearchBrand() {
    //    var searchBrand = $('div.search-brand');
    //    var searchMobile = $('#offcanvas-mobile-search');
    //    if(searchBrand.length && searchMobile.length){
    //        var searchWrap = $('div.search-wrap > div.wrap',searchMobile);
    //        var searchCategory =  searchWrap.children('div.search-category');
    //        if (searchWrap.length && screen.width <= 991) {
    //            searchMobile.addClass('h-auto');
    //            if(searchCategory.length > 0){
    //                searchCategory.before(searchBrand);
    //            }else{
    //                searchWrap.append(searchBrand);
    //            }
    //        }
    //    }
    //}


    function showSearchBrand() {
        var searchBrand = $('div.search-brand');
        var searchMobile = $('#offcanvas-mobile-search');
        if (searchBrand.length && searchMobile.length) {
            var searchWrap = $('.offcanvas__search-wrap', searchMobile);
            var searchCategory = searchWrap.children('div.search-category');
            if (searchWrap.length && screen.width <= 991) {
                searchBrand.prepend('<div class="search-brand__title">Фильтр по брендам</div>');
                //searchMobile.addClass('h-auto');
                if (searchCategory.length > 0) {
                    searchCategory.after(searchBrand);
                } else {
                    searchWrap.append(searchBrand);
                }
                showAllBrands();
            }
        }


        //if (screen.width < 991) {
        //  showAllBrands();
        //}


    }
    // Add class to last element in search category
    //function addClassLastElement() {
    //  var arr = $('li#nav99>ul>li');
    //  var x = arr.length % 2;
    //  if (x == 1) {
    //    $('.search-category').addClass('odd');
    //  }
    //}

    function showAllBrands() {
        var link = $('<li><a href="#">...</a></li>').click(function () {
            $('.search-brand>ul').each(function () {
                $(this).find('li').slice(7).show();
            });
            $(this).remove();
            return false;
        });

        $('.search-brand>ul').each(function () {
            var ln = $(this).find('li').length;
            if (ln > 6) {
                $(this).find('li').slice(7).hide();
                $(this).find('li:eq(6)').after(link);
            }
        });

    }

    showSearchBrand();




})(jQuery);

/*****************************
* Modal windows activate
*****************************/
var srv_show;
if (typeof (SRV_Show) !== 'undefined') {
    srv_show = new SRV_Show({
        scroll: 'scroll',
        centered: 'centered',
    });
}